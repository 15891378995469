import React from 'react'
import styled from '@emotion/styled'
import Container from '../components/shared/container'
import { mediaQueries } from '../theme'
import Gallery from '../components/gallery/gallery'
import PageMetadata from '../components/page-metadata'
import Layout, { BaseLayout } from '../components/layout'

const isBrowser = typeof window !== 'undefined'

const InnerContainer = styled(Container)`
  padding: 0;
  margin-bottom: ${p => p.theme.space[23]};

  ${mediaQueries.md} {
    padding: ${p => `0 ${p.theme.space[10]}`};
  }

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[28]};
  }
`

// ssr metadata only, see https://github.com/gatsbyjs/gatsby/issues/14601
// and https://github.com/gatsbyjs/gatsby/issues/17914
const PageLayout = isBrowser ? Layout : BaseLayout

const TemplatesPage = ({ location }) => (
  <PageLayout location={location}>
    <PageMetadata
      title="Getform Templates: Dozens of Stunning Forms in One Place"
      description="Explore the collection of professionally crafted forms to give your conversion journey an amazing headstart."
      keywords="getform templates, getform template gallery, getform forms"
      robots="noindex"
    />
    {isBrowser ? (
      <InnerContainer>
        <Gallery basePath="/templates" />
      </InnerContainer>
    ) : null}
  </PageLayout>
)

export default TemplatesPage
